
<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="this.$t('商品分类.分类名称')" prop="categoryName" >
        <a-input v-model="form.categoryName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品分类.分类名称')" />
      </a-form-model-item>
<!--      <a-form-item :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
          <span slot="locale" slot-scope="text, record">
           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
          </span>
          <div slot="categoryName" slot-scope="text, record" >
            <a-input v-model="record.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品分类.分类名称')" @blur.native.capture="validatorLocaleName"   />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-button type="primary" @click="getTranslation(record)">
              {{$t('通用.按钮.翻译')}}
            </a-button>
          </div>
        </a-table>
        <span style="color: red;">{{ errorMessage }}</span>
      </a-form-item>-->

      <a-form-model-item :label="this.$t('商品分类.分类图片')" prop="categoryPic">
        <a-upload
          name="categoryPic"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.categoryPic"
            :src="form.categoryPic"
            alt="categoryPic"
            style="height: 200px; width: 200px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{$t('通用.按钮.上传')}}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 60 x 60</span>
      </a-form-model-item>

      <a-form-model-item :label="this.$t('商品分类.上级分类')" prop="pid" >
        <a-tree-select v-model="form.pid" style="width: 100%"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="cateOptions" :placeholder="this.$t('通用.文本.请选择')+this.$t('商品分类.上级分类')"
                       :replaceFields="{ children: 'children', title: 'categoryName', key: 'id', value: 'id' }"
                       tree-default-expand-all>
        </a-tree-select>
      </a-form-model-item>
<!--   上面是树的写法,如果有需要可以换成那个   -->
      <!--   下面是列表的写法,如果有需要可以换成这个   -->
<!--      <a-form-model-item :label="this.$t('商品分类.上级分类')" prop="pid" >
        <a-select style="width: 100%" v-model="form.pid" :placeholder="$t('通用.输入.请选择')+$t('商品分类.上级分类')">
          <a-select-option v-for="(item, index) in cateOptions" :value="item.id" :key="index">
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>-->
      <a-form-model-item :label="this.$t('商品分类.是否显示')" prop="isVisible" >
        <a-radio-group v-model="form.isVisible" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('商品分类.是否首页展示')" prop="isHomeVisible" >
        <a-radio-group v-model="form.isHomeVisible" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('商品分类.是否参与筛选')" prop="isFilter" >
        <a-radio-group v-model="form.isFilter" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('通用.文本.排序')" prop="sort" >
        <a-input-number :maxLength="6" style="width: 50%"  @change="limitSort" v-model="form.sort" :placeholder="$t('通用.输入.请输入')+$t('通用.文本.排序')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCategory, listCategory,addCategory, updateCategory } from '@/api/goods/category'
import {mapGetters} from 'vuex'
import {generateFilePath,uploadOss, uploadObject} from "@/api/tool/oss";
import CustomDictTag from "@/components/DictCustomTag";
import {translation} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('商品分类.分类名称'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'categoryName' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      cateOptions: [],
      loading: null,
      imgCaseList: [],
      // 表单参数
      form: {
        id: null,
        categoryName: null,
        localeList: [],
        categoryPic: null,

        pid: null,

        level: null,

        isVisible: null,
        isFilter: null,
        isHomeVisible: null,
        sort: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        categoryName: [
          { required: true, message: this.$t('商品分类.分类名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        pid: [
          { required: true, message:  this.$t('商品分类.上级分类')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        categoryPic: [
          { required: true, message:  this.$t('商品分类.分类图片')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isVisible: [
          { required: true, message:  this.$t('商品分类.是否显示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isHomeVisible: [
          { required: true, message:  this.$t('商品分类.是否首页显示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isFilter: [
          { required: true, message:  this.$t('商品分类.是否参与筛选')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.cateOptions = []
      this.form = {
        id: null,
        categoryName: null,
        categoryPic: null,
        localeList: [],
        pid: null,
        level: null,
        isFilter: null,
        isVisible: null,
        isHomeVisible: null,
        sort: null,
        createTime: null,
        remark: null,
      }

    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.categoryName) {
        return
      }
      let data = {"text": this.form.categoryName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage='';
      return true;
    },
    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1 ;
      this.customDict.LocaleEnum.forEach(e=>{
        localeList.push({"id":i ,"locale":e.type,'name':null })
        i++;
      })
      this.form.localeList = localeList;
    },
    /*getCateList() {
      listCategory({level: 1}).then(response => {
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点') }
        /!*cate.children = this.handleTree(response.data, 'id','pid')*!/
        this.cateOptions.push(cate)
        response.data.forEach(e=>{
          this.cateOptions.push(e)
        })
      })
    },*/
    getCateList() {
      /*这个方法是树的写法*/
      listCategory().then(response => {
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.getCateList();
      this.form.isFilter=false
      this.form.isHomeVisible=true
      this.form.pid="0"
      this.form.isVisible=true
      this.formType = 1
      this.open = true
      this.initLocale();
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.getCateList();

      this.formType = 2
      const id = row ? row.id : ids
      getCategory({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },

    limitSort(sort) {
      console.log(sort)
      let val = sort;
      if(val==null||val==undefined||val==''||val<0){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      }else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'categoryPic', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.imgCaseList.push({status: 'done', url: res, uid: this.getUidRandom(), name: res})
          console.log(_this.imgCaseList)
          this.form.imgCaseList= []
          _this.imgCaseList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgCaseList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgCaseList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgCaseList = []
      this.imgCaseList = fileList
      fileList.forEach(e => {
        imgCaseList.push(e.url)
      })
      this.form.imgCaseList = imgCaseList
    },
    //多图上传结束


    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
       /* let localeValid = this.validatorLocaleName();
        if (!localeValid) {
          return false;
        }*/
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCategory(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCategory(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  line-height: 100px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 26px !important;
    color: #fff !important;
  }
}
</style>